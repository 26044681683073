

@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
*{
    margin: 0;
    padding: 0;
    letter-spacing: -0.48px;
    /* -webkit-tap-highlight-color: transparent; */
    -webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family:'Noto Sans KR','Noto Sans Korean', sans-serif;
    font-size: 14px;
}
a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}
button{
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
}
input[type="text"],
input[type="number"],
textarea{
    font-size: 16px;
}
.f-bold {
    font-weight: bold;
}
.m10t {
    margin-top: 10px;
}
.m24t {
    margin-top: 24px;
}
.p50t {
    padding-top: 50px;
}

.m8r {
    margin-right: 8px;
}
.m6t {
    margin-top: 6px;
}
.m5b {
    margin-bottom: 5px;
}
.m10b {
    margin-bottom: 10px;
}
.m11b{
    margin-bottom: 11px;
}
.m16b {
    margin-bottom: 16px;
}
.m21b {
    margin-bottom: 21px;
}
.m24b {
    margin-bottom: 24px;
}
.f-s11 {
    font-size: 11px;
}
.f-s16 {
    font-size: 16px;
}
.f-s18 {
    font-size: 18px;
}
.color-fa233b {
    color: #fa233b;
    font-weight: bold;
}
.d-flex{
    display: flex;
    justify-content: space-between;
}
.popup-btn-close {
    position: absolute;
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url(/src/assets/img/btn_closex3.png)no-repeat;
    background-size: 100%;
    color: transparent;
    top: 10px;
    right: 0;
    transform: translate(-16px, 0%);
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
}
.btn-area-div {
    padding: 16px;
}
.btn-area-diver3 {
    padding: 24px 16px;
    background: white;
}
.btn {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background: #fa233b;
    border: 1px solid #fa233b;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    /* box-sizing: border-box; */
}
.result-a {
    font-size: 16px;
}
.bg-arrow-w {
    background: url(/src/assets/img/arrow_right_s_w_x03.png)no-repeat right 4px;
    padding-right: 10px;
    background-size: 6px;
    font-size: 11px;
}
.page-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
}
.page-loading .sweet-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}