.connect-wrap {
    position: fixed;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    z-index:2;
    background: #fff;
    opacity:1;
}

.connect-wrap.none {
    opacity:0;
    transition: opacity .35s ease-in-out;
}