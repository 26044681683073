/* 팝업 */
.popup-bg {
    /* display: none; */
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index:4;
}
.popup-time-extension {
    z-index: 3;
}
.bg{
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.popup-bg .popup-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    z-index: 5;
    background: white;
    text-align: center;
    border-radius: 6px;
}
.popup-bg .popup-top{
    position: relative;
    display: flex;
    width: 300px;
    height: 50px;
    padding: 13px 20px;
    border-radius: 6px 6px 0 0;
    background: #fa233b;
    justify-content: space-between;

    color: white;
    font-weight: bold;
    font-size: 16px;
}
.popup-bg .popup-top{
    padding-bottom: 24px;
}
.popup-bg .popup-bottom,
.popup-bg .popup-center{
    background: white;
    border-radius: 0 0 6px 6px;
}
.popup-bg .popup-center.text{
    padding-bottom: 8px;
}
.popup-bg .popup-center.text .popup-center-inner{
    background: #f5f5f5;
}
.popup-bg .popup-center .category-list{
    height: 44px; line-height: 44px;
    text-align: left;
    border-bottom: 1px solid #eee;
}
.popup-bg .popup-center .category-list:last-child{
    border-bottom: 0;
}
.popup-bg .popup-center .category-list .link{
    display: inline-block; text-indent: 20px; width: 100%;
}

.popup-bg .popup-center .popup-img-div{
    padding: 24px 0;
}
.popup-bg .popup-center .popup-img{
    width: 60px;
    height: 60px;
    margin: 0 auto 16px;
}
.popup-bg .popup-center .popup-img img{
    width: 100%;
}
.popup-bg .popup-center .popup-text{
    padding: 16px;
    text-align: left;
    font-size: 12px;
    background: white;
    border-top: 1px solid #eee;
}
.popup-bg .popup-center .popup-text.p12{
    padding-bottom: 12px;
}
.popup-bg .popup-center .popup-text.line{
    border-bottom: 1px solid #eee;
    border-top: 0;
    margin-bottom: 6px;
}
.popup-bg .popup-center .popup-text .number-set.gyeong-go{
    font-size: 11px;
    color: #888;
    line-height: 18px;
}
.popup-bg .popup-center .popup-text .outline{
    /* text-indent: 6px; */
    line-height: 22px;
    padding-left: 6px;
}
/* .popup-bg .popup-center .popup-text .outline p{
    padding-left: 14px;
} */
.popup-bg .popup-center .number-set.point{
    padding: 0px 0px 0px 14px;
}
.popup-bg .popup-center .popup-table{
    padding: 0 16px;
}
.popup-bg .popup-center .popup-grid{
    display: grid;
    display:-ms-grid;
    grid-template-columns: 37% 63%;
    -ms-grid-columns: 37% 63%;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.popup-bg .popup-center .popup-grid div{
    /* padding: 8px 24px; */
    padding: 8px 22px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.popup-bg .popup-center .popup-grid div:nth-child(1){
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.popup-bg .popup-center .popup-grid div:nth-child(2){
    -ms-grid-row: 1;
    -ms-grid-column: 2;
}
.popup-bg .popup-center .popup-grid div:nth-child(3){
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}
.popup-bg .popup-center .popup-grid div:nth-child(4){
    -ms-grid-row: 2;
    -ms-grid-column: 2;
}
.popup-bg .popup-center .popup-grid.balsin div{
    padding: 8px 10px;
}
.popup-bg .popup-center .popup-grid.balsin .bg-gray{
    background: #f8f8f8;
    padding: 9px 0 11px;
}
.popup-bg .popup-center .popup-grid.balsin .lh34{
    line-height: 34px;
}
.popup-bg .popup-center .popup-grid.store-call div{
    /* padding: 10px 16px; */
    padding: 10px 8px;
}
.popup-bg .popup-center .popup-grid div.border-r-0{
    border-right:0;
}
.popup-bg .popup-center .popup-grid div.border-b-0{
    border-bottom:0;
}
.popup-bg .popup-center .pad16{
    padding: 0 16px;
}
.popup-bg .popup-center .popup-center-title{
    border-bottom: 1px solid #eee;
    padding: 16px 0;
}
.popup-bg .popup-center .popup-center-con{
    padding: 16px 0; padding: 16px 0;
    text-align: left;
    width: 76%;
    margin: 0 auto;
    line-height: 24px;
}
.popup-bg .popup-center .popup-chat-m{
    padding: 50px 0 40px;
}
.popup-bg .call-question{
    padding: 16px; font-size: 12px;
    text-align: left;
}
.popup-bg .call-question .iui-textarea{
    font-size: 14px; padding: 12px;
    width: 268px; height: 140px; background: #f8f8f8; border: 1px solid #eee;
    resize: none;
}
.popup-bg .call-question .call-question-notice{
    line-height: 22px;
    text-align: center;
}
.popup-bg .popup-center .popup-grid.store-call .call-store-img{
    position: relative; padding: 0; line-height: 0;
    padding: 8px 8px 8px 6px; box-sizing: initial;
}
.popup-bg .popup-grid.store-call .call-store-img img{
    width: 68px;
    height: 67px;
    border-radius: 4px;
}
.popup-bg .popup-grid.store-call .store-title{
    line-height: 66px;
}
.call-question .store-call-question{
    line-height: 20px;
}
.popup-bg .text-c{
    text-align: center;
}
.popup-bg .call-question .popup-grid{
    display: grid;
    grid-template-columns: 30.9% 69.1%;
    border: 1px solid #eee;
}
.popup-bg .number-set p.number::before {
    left: 20px;
}
.popup-bg .number-set p.point::before{
    left: 22px;
}
.popup-bottom {
    width: 100%;
    padding: 0 16px;
    text-align: center;
    background: white;
}
.popup-bottom .popup-bottom-inner{
    padding: 24px 0px;
    border-top: 1px solid #eee;
}
.popup-bottom .popup-bottom-inner.border-t-0{
    border-top: 0px;
    display: flex;
    justify-content: center;
}
.popup-bottom .popup-bottom-btn{
    display: inline-block;
    width: 100px;
    height: 36px;
    line-height: 34px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    background: #fa233b;
    border-radius: 4px;
}
.popup-bottom .popup-bottom-btn.call{
    width: 120px;
}
.popup-bottom .popup-bottom-btn.store-call{
    width: 166px;
}
.popup-bottom .popup-bottom-btn .bg-arrow-w{
    background: url(/src/assets/img/arrow_right_s_w_x03.png)no-repeat right 4.5px;
    padding-right: 14px;
    background-size: 7.2px;
}
.popup-bottom .popup-bottom-btn.cancel{
    background: #b1b1b1;
}
.popup-bottom .popup-bottom-btn.review{
    background: #3f71c8;
}
.popup-bg .popup-top.trainer{
    text-align: left;
}
.popup-bg .popup-center .sangdam{
    border-top: 1px solid #ddd;
    padding-top: 24px;
}
.popup-bg .popup-center .sangdam .trainer-inner{
    border-bottom: 1px solid #ddd;
    padding-bottom: 24px;
}
.popup-bg .popup-center .trainer-inner{
    /* padding-left: 75px; */
    text-align: left;
}
.popup-bg .popup-center .trainer-title{
    font-weight: bold;
    color: black;
    font-size: 16px;
    margin-bottom: 4px;
}
.popup-bg .popup-center .trainer-intro{
    font-size: 12px;
    color: #888;
}
.popup-center .sangdam .trainer-time{
    color: black;
    font-weight: bold;
    text-align: left;
}
.popup-bg .popup-center .sangdam .sangdam-data{
    line-height: 32px;
}
.popup-bg .popup-center .sangdam .sangdam-time{
    line-height: 18px;
    font-size: 24px; font-weight: bold;
    color: #0baa84;
}
.popup-bg .popup-center .content,
.popup-bg .popup-bottom .popup-btn{
    font-size: 16px;
}
.popup-bg .popup-center.text{
    font-size: 14px;
    text-align: left;
}
.popup-bg .popup-center.text .content{
    font-size: 14px;
    line-height: 30px;
    color: #333;
}
.popup-bg .popup-center.text .color_gray{
    font-size: 12px;
    line-height: 8px;
    color: #888;
}
.color_fa233b{
    color: #fa233b;
    font-size: 14px;
}
.color_fa233b-b{
    color: #fa233b;
    font-size: 14px;
    font-weight: bold;
}
.en-breck{
	word-break:break-word;
}
.popup-img-div.chat-end-p{
    margin: 16px 0 46px;
}
.popup-img-div.chat-end-p p{
    font-size: 18px;
    line-height: 28px;
}
/* 상담시간 연장 */
.popup-center .sangdam-inner-time{
    border-bottom: 1px solid #eee;
    padding: 16px; text-align: left;
}
.popup-center .radio-box{
    position: relative;
    width: 100%;
}
.popup-center .radio-box label{
    position: relative;
}
.popup-center .radio-label input{
    display: none;
}
.popup-center .sangdam-inner-pay{
    text-align: left;
    padding: 16px 16px 24px;
}
.popup-center .s-center{
    display: flex;
    flex-direction: column;
    justify-content : center;
    /* width: 70%; */
    font-size: 14px; margin-right: 16px;
}
.popup-center .sangdam-inner-coin{
    border: 1px solid #ddd; padding: 9px;
    background: #f8f8f8;  width: 70%;
    text-align: left;
}
.popup-center .sangdam-inner-none{
    padding: 24px 16px;
    text-align: center;
    line-height:24px;
}
/* 팝업 라디오 */
.popup-center .radio-label{
    width: 100%;
    height: 100%;
    display: inline-block;
    text-indent: 22px;
}
.popup-center .radio-span-inner{
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 0%;
    transform: translate( 0%, -50%);
    width: 18px;
    height: 18px;
    background-color: white;
    border: 1px solid #b1b1b1;
    border-radius: 50%;
}
/* radio box */
input[type="radio"]:checked + span .radio-span-inner {
    border: 1px solid #b1b1b1;
}
input[type="radio"]:checked + span .radio-span-inner:after {
    display: block;
}
.radio-span-inner:after {
    border-radius: 50%;
}
.popup-center .radio-span-inner::after {
    content: "";
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background: #333;
    transform: translateX(-50%) translateY(-50%);
}