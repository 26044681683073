@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
*{
    margin: 0;
    padding: 0;
    letter-spacing: -0.48px;
    /* -webkit-tap-highlight-color: transparent; */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family:'Noto Sans KR','Noto Sans Korean', sans-serif;
}
html, body {
    height: 100%;
}
body{
    background: #f8f8f8;
}
.wrap{
    box-sizing: border-box;
    font-size: 14px;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    position: relative;
}
a{
    text-decoration: none;
    color: black;
}
.screen-wrap {
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
/* 채팅헤더 */
.chat-header{
    height: 50px;
    padding: 13px 16px;
    /* background: url(/src/assets/img/arrow_left_s_w_x03.png)no-repeat 16px 52% #fa233b; */
    background: #fa233b;
    background-size: 8px;
    color: white;
    position: relative;
}
.hide {
    overflow: hidden;
    text-indent: -999px;
    position: absolute;
    color: transparent;
}
.chat-header .back {
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    width: 30px;
    height: 50px;
}
.chat-header .header-title {
    display: inline-block;
    /* text-indent: 15px; */
    color: white;
    font-size: 16px;
}
.chat-end-text {
    display: inline-block;
    float: right;
    margin-right: 39px;
    line-height: 24px;
    font-weight: bold;
    font-size: 14px;
}
.chat-header a {
    font-size: 16px;
    font-weight: bold;
}
.chat-end {
    display: inline-block;
    position: absolute;
    height: 30px;
    color: white;
    background: url(/src/assets/img/chat_endx3.png)no-repeat center center;
    background-size: 30px;
    color: transparent;
    top: 50%;
    right: 0;
    transform: translate(0px, -50%);
}
.chat-header .my-page-c {
    display: inline-block;
    position: absolute;
    float: right;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    color: transparent;
    background: url(/src/assets/img/btn_closex3.png)no-repeat center center;
    background-size: 30px;
}
/* 채팅 */
.chat-wrap{
    height: calc(100% - 222px);
    background: #fff;
    -ms-overflow: auto;
    overflow: auto;
    width: 100%;
}
.chat-wrap.callee {
   height: calc(100% - 198px);
}
.text-input-sec {
    width: calc(100% - 31px);
}
.text-input-sec .text-input{
    resize: none;
    /* min-width: 244px; */
    width: 100%;
    height: 36px;
    background: white;
    border-radius: 50px;
    border: 1px solid #dde1e4;
    outline: none;
    padding: 7px 16px;
}
.chat-bg{
    background: white; padding: 16px;
    /* overflow: scroll; */
}
.chat-info{
    padding: 12px;
    background: #f6f6f6;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
    /* position: fixed; */
    width: 100%;
    /* top: 50px; */
    /* z-index: 1; */
}
.chat-info-time{
    position: absolute;
    right: 16px; top: 50%;
    transform: translate(0, -50%);
}
/* 말풍선 div */
.chat_bub{
    /* background: url(/src/assets/img/chat_topx3.png)no-repeat top left,
    url(/src/assets/img/chat_bottomx3.png)no-repeat bottom left; */
    background-size: 100%, 100%;
    padding: 7px 0;
    margin-right: 6px;
    max-width: 57%;
    position: relative;
}
/* 기본 말풍선 */
.chat_basic{
    display: inline-block;
    background: #e9ecef;
    color: black;
    padding: 7px 16px;
    height: auto;
    margin-left: 4px;
    border-radius: 0px 50px 50px 50px;
    line-height: 24px;
    position: relative;
    word-break:break-word;
}
.chat_basic::after{
    position: absolute;
    display: inline-block;
    content: '';
    width: 26px;
    height: 17px;
    background: url(/src/assets/img/chat_basic_lx3.png)no-repeat top left;

    background-size: 100%;
    top: -0.52px;
    left: -9px;
    /* right: -9px; */
}
/* 어드민 말풍선 */
/* .chat_basic.red::after{ */
.admin .chat_basic::after{
    background: url(/src/assets/img/chat_red_lx3.png)no-repeat top left;
    background-size: 100%;
    right: 0;
    left: -9px;
}
/* .chat_basic.red{ */
.admin .chat_basic{
    background: #f55560;
    color: white;
}
/* 내 말풍선 */
.chat-wrap .my-message{
    display: flex;
    /* justify-content: flex-end; */
    flex-direction: row-reverse;
}
.my-message .chat_basic::after{
    background: url(/src/assets/img/chat_bulex3.png)no-repeat top right;
    background-size: 100%;
    right: -9px;
    left: initial;
}
/* .chat_basic.blue{ */
.my-message .chat_basic{
    background: #5877ac;
    color: white;
    border-radius: 30px 0px 30px 30px;
}
/* 입력중 */
.text-ing{
    margin-bottom: 5px;
    position: fixed;
    bottom: 50px;
    left: 10px;
}
.text-ing .text-ing2{
    background: url(/src/assets/img/dusrufwnd.png)no-repeat;
    background-size: 100%;
    width: 68px;
    height: 26px;
    padding: 4px 6px;
    text-align: center;
    text-indent: 5px;
}
.text-ing .f-s11{
    color: white;
}
/* 시간 텍스트 */
.chat_text_div{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.chat_text{
    font-size: 10px;
    color: #aeb6be;
}
.chat_text.bottom{
    margin-bottom: 10px;
}
.chat_text.red{
    color: #fa233b;
}
.chat_text.blue{
    color: #5877ac;
}
/* 상담시간 */
.chat_time{
    background: white;
    border: 1px solid #ddd;
    border-radius: 50px 0px 0px 50px;
    padding: 3px 11px;
    font-size: 12px;
}
.chat_time.right{
    border-radius: 0px 50px 50px 0px;
    border-left: 0;
}
.chat-content{
    height: 50px; padding: 7px 16px; background: #e9ecef;
    position: absolute;
    bottom: 0;
    width: 100%;
}
/* 더보기 */
.chat-plus{
    display: inline-block;
    background: url(/src/assets/img/chat_plusx3.png)no-repeat center center white;
    background-size: 16px;
    min-width: 36px; height: 36px;
    border: 1px solid #dde1e4;
    border-radius: 4px; color: transparent;
    margin-right: 16px;
}
/* 보내기 */
.chat-enter{
    display: inline-block;
    background: url(/src/assets/img/chat_enterx3.png)no-repeat center 3px;
    background-size: 100%;
    width: 31px;
    height: 31px;
    color: transparent;
    cursor: pointer;
}









/* 공통 */
.d-flex{
    display: flex;
    justify-content: space-between;
}
.d-flex-l {
    display: flex;
    justify-content: left;
}
.p-rela {
    position: relative;
}
.f-bold {
    font-weight: bold;
}
.small-profile {
    width: 44px;
    height: 44px;
    border-radius: 6px;
    border: 1px solid;
    margin-right: 10px;
}
.small-profile img {
    width: 100%;
}
.item-name.text-tag{
    display: inline-block;
    background: #fa233b;
    border-radius: 2px;
    margin-right: 6px;
    border: 1px solid #fa233b;
    color: white;
    font-size: 11px;
    font-weight: bold;
    padding: 0px 4px;
    line-height: 15px;
    height: 18px;
    margin-top: 2px;
}
.item-name.badge::before {
    display: inline-block;
    content: "사주";
    width: 30px;
    height: 18px;
    line-height: 18px;
    border-radius: 2px;
    color: white;
    background: #fa233b;
    margin-right: 6px;
    font-weight: bold;
    font-size: 11px;
    position: relative;
    text-align: center;
    top: -1px;
}
.item-name.badge.saju::before {
    content: "사주";
}
.item-name.badge.tarot::before {
    content: "타로";
}
.item-name.badge.sinjeom::before {
    content: "신점";
}
.item-name.badge.pungusu::before{
    content: "풍수";
}
.item-name.badge.jagmyeong::before{
    content: "작명";
}

/* 코인채팅상담 메인 */
.coin-chat-wrap {
    background: #fa233b;
    padding-bottom: 15px;
}
.coin-chat-wrap .store-info {
    width: 100%;
    height: 160px;
}
.coin-chat-wrap .store-info-inner {
    text-align: center;
    background: #fa233b;
}
.coin-chat-wrap .store-name {
    color: white;
    font-size: 18px;
}
.coin-chat-wrap .store-img {
    width: 152px;
    height: 152px;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 10px;
}
.coin-chat-wrap .store-img img {
    width: 100%;
    border-radius: 10px;
}
.coin-chat-wrap .store-reserv {
    /* height: 430px; */
    background: white;
    border-radius: 30px 30px 0 0;
    padding-top: 89px;
    text-align: center;
}
.coin-chat-wrap .store-reserv .loding-bar {
    width: 80px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 24px;
}
.coin-chat-wrap .store-reserv .loding-bar img {
    width: 100%;
}
.coin-chat-wrap .store-reserv .loding-text {
    font-size: 16px;
}
.coin-chat-wrap .reserv-data {
    font-size: 20px;
    font-weight: bold;
    color: #fa233b;
}
.coin-chat-wrap .reserv-data-detail {
    font-size: 14px;
    color: #222;
}
.coin-chat-wrap .store-reserv .btn-area-div {
    border-top: 1px solid #eee;
    text-align: left;
    color: #888;
    /* font-size: 12px; */
    padding-bottom: 0;
}
.coin-chat-wrap .number-set {
    padding: 0px 0px 0px 14px;
    line-height: 22px;
}
.coin-chat-wrap .number-set p{
    font-size: 12px;
}
.number-set p.point::before {
    position: absolute;
    left: 16px;
    content: "·";
}
.coin-chat-wrap .number-set p.point::before {
    left: 20px;
}
.sangdam-time{
    font-size: 11px;
    padding: 1px 5px;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    margin-right: 6px;
    background: white;
    line-height: 18px;
}
.yeonjang{
    background: #fa233b;
    color: white;
    padding: 3px 9px;
    border-radius: 2px;
    /* font-size: 11px; */
    line-height: 13px;
    cursor: pointer;
}
.admin_basic{
    background: #f55560;
    display: inline-block;
    padding: 2px 10px 2px 8px;
    border-radius: 50px;
    color: white;
}
.admin .chat_bub{
    max-width: 100%;
    width: 100%;
    background: #f8f6f1;
    height: auto;
    padding: 14px;
    border-radius: 12px;
    margin-right: 0;
    margin-bottom: 8px;
}
.admin .bell{
    background: url(/src/assets/img/hong2_bellx3.png)no-repeat left center;
    background-size: 10px;
    padding-left: 14px;
    font-size: 11px;
}
/* 안읽음 */
.chat_text.why{
    color: #5877ac;
}
.coin-connect-wrap{
    height: 100vh; background: white; position: relative;
}
.coin-connect-wrap .loding{
    position:absolute; top: 45%; left: 50%; transform: translate(-50%, -50%);
}
.coin-connect-wrap .loding-bar{
    width: 70px; height: 70px; margin: 0 auto; margin-bottom: 24px;
}
.coin-connect-wrap .loding-bar img{
    width: 100%;
}
.chat-time-info {
    padding: 15px; background: #f8f8f8; border-bottom: 1px solid #e5e5e5;
}
.chat-time-info  .time-limit {
    line-height: 22px;
}
